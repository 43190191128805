<template>
  <default-layout>
    <template #content>
      <div class="mx-auto">
        <img src="../../assets/images/logo.png" alt="" class="mx-auto h-12" />
      </div>
      <h1 class="title my-4">Sign-up</h1>

      <article class="px-5 pb-20 mx-auto">
        <header class="text-lg text-center">
          <p class="text-sm">
            Please enter an
            <br />
            e-mail address and password which will
            <br />
            be used to access this account.
          </p>
        </header>
        <template v-if="error">
          <transition name="fade">
            <p class="bg-red-100 p-5 my-5 border border-red-200 rounded text-red-500">
              {{ error }}
            </p>
          </transition>
        </template>

        <form @submit.prevent="validateBeforeSubmit" class="flex flex-col space-y-4 mt-8">
          <div>
            <form-input
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              class="w-full text-center bg-white px-4 py-2 border-solid border-b-2 border-primary focus:outline-none"
              v-model="email"
              v-validate="'required|email'"
            />
            <span v-show="errors.has('email')" class="text-xs text-red-500">{{ errors.first("email") }}</span>
          </div>

          <div>
            <form-input
              id="password"
              type="password"
              name="password"
              placeholder="Password"
              class="w-full text-center bg-white px-4 py-2 border-solid border-b-2 border-primary focus:outline-none"
              v-model="password"
              v-validate="'required'"
            />
            <span v-show="errors.has('Password')" class="text-xs text-red-500">{{ errors.first("Password") }}</span>
          </div>

          <p class="text-center mt-8">Please enter a valid registration code</p>

          <form-input
            placeholder="Registration Code"
            name="code"
            v-validate.disable="'required|isValidCode'"
            v-model="codeInput"
          />
          <span v-show="errors.has('code')" class="text-xs text-red-500">{{ errors.first("code") }}</span>

          <div>
            <p class="text-center text-gray-500" style="font-size: 8px">
              By continuing you're agree to Tuttnauer's
              <a href="#">Privacy Notice</a>
              .
            </p>
            <v-btn
              role="button"
              type="submit"
              class="form-button form-button--full mb-4 uppercase"
              :loading="loading"
              :disabled="loading"
              rounded
              outlined
              large
            >
              Sign-up
            </v-btn>
            <nav-button to="/" outline>Back</nav-button>
          </div>
        </form>
      </article>
    </template>
  </default-layout>
</template>

<script>
import { FormInput, NavButton } from "@/components/";
import DefaultLayout from "@/layouts/DefaultLayout";
import { Validator } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  components: { DefaultLayout, FormInput, NavButton },
  data: () => ({
    email: "",
    password: "",
    codeInput: "",
    codeData: undefined,
    loading: false
  }),
  computed: {
    ...mapGetters("auth", ["currentUser", "error"])
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.trySignUp();
          return;
        }
        console.log("The form is not submitted. Correct the errors");
      });
    },

    async trySignUp() {
      this.loading = true;
      await this.$store
        .dispatch("auth/signUp", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "SIGN_UP",
            clinicUid: "",
            notes: `User ${this.email} signed up`,
            uid: "Not defined",
            userEmail: this.email,
            userName: "Not defined"
          });
        })
        .then(() => this.$store.dispatch("auth/setCodeInactive", this.codeData.id))
        .then(() => {
          if (!this.error) {
            this.$router.push("/");
          }
        })
        .catch((error) => console.error("Error writing document: ", error.message))
        .finally(() => (this.loading = false));
    }
  },
  mounted() {
    const _this = this;

    const regCodeValidator = {
      getMessage: (field, params, data) => data.message,

      async validate(value) {
        await _this.$store.dispatch("auth/checkRegistrationCode", value).then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            _this.codeData = await { ...doc.data(), id: doc.id };
          });
        });

        if (_this.codeData == undefined) {
          return {
            valid: false,
            data: {
              message: `The code is not valid.`
            }
          };
        } else if (_this.codeData && _this.isActive == undefined) {
          return {
            valid: true
          };
        } else if (_this.codeData && _this.codeData.isActive !== undefined && _this.codeData.isActive == false) {
          return {
            valid: false,
            data: {
              message: `The code has been already applyied.`
            }
          };
        } else {
          return {
            valid: true
          };
        }
      }
    };

    Validator.extend("isValidCode", regCodeValidator, {
      immediate: false
    });
  }
};
</script>
